.DeviceMenuButton {
  width: 70px;
  height: 64px;
  cursor: pointer;
  background-color: #fff;
  margin: 4px;
  padding: 4px;
  display: inline-block;
}

.DeviceMenuButton:hover {
  background-color: #c8c8c8;
}

.DeviceMenuButton .icon {
  width: 100%;
  text-align: center;
  font-size: 24px;
  display: block;
}

.DeviceMenuButton .label {
  width: 100%;
  text-align: center;
  margin-top: 4px;
  font-size: 12px;
  display: block;
}

/*# sourceMappingURL=index.1fd502c4.css.map */
