.DeviceMenuButton {
    width: 70px;
    height: 64px;
    display: inline-block;
    background-color: #fff;
    cursor: pointer;
    padding: 4px;
    margin: 4px;
}

.DeviceMenuButton:hover {
    background-color: #c8c8c8;
}

.DeviceMenuButton .icon {
    font-size: 24px;
    width: 100%;
    text-align: center;
    display: block;
}

.DeviceMenuButton .label {
    display: block;
    margin-top: 4px;
    font-size: 12px;
    width: 100%;
    text-align: center;
}